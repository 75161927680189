.rbc-allday-cell {
  display: none !important;
}

.rbc-header {
  font-size: 14px;
  padding-top: 10px;
}

.rbc-time-header-gutter {
  min-width: 64px;
}

.rbc-day-slot .rbc-event-label {
  display: none;
}

.rbc-time-slot {
  width: 100%;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
}

.rbc-day-slot .rbc-time-slot {
  border: 0;
}

.user-events-calendar .rbc-day-slot .rbc-time-slot {
  border-bottom: 1px solid #ddd;
}

.user-fixed-availability-calendar .rbc-day-slot .rbc-time-slot {
  border-bottom: 1px solid #ddd;
}

.user-fixed-availability-calendar .rbc-events-container {
  cursor: pointer;
}

.user-fixed-availability-calendar .rbc-day-slot .rbc-event {
  border: 0;
  background-color: #91ba9d;
}

.rbc-day-slot .rbc-timeslot-group {
  border: 0;
}

.rbc-day-slot .rbc-events-container {
  margin: 0;
}

.rbc-time-column.rbc-time-gutter .rbc-timeslot-group {
  border-bottom: 1px solid #ddd;
}

.rbc-day-slot .rbc-event {
  padding: 0px;
  border-radius: 1px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-bottom: 1px solid #ddd;
}

.rbc-day-slot .rbc-event {
  background: transparent;
}

.rbc-event.available {
  background-color: #ceedd8;
  border-bottom: 1px solid #91ba9d;
  border-right: 1px solid #91ba9d;
  color: #444;
}

.rbc-event.available-outside {
  background-color: #FFFFCC;
  border-bottom: 1px solid #FFC000;
  border-right: 1px solid #FFC000;
  color: #444;
}

.rbc-day-slot .rbc-event:hover {
  background-color: #a0deb3;
}

.rbc-event .rbc-event-content {
  flex: 0;
  border: 0;
  background-color: transparent;
  height: auto;
}

.rbc-event.available .rbc-event-content, .rbc-event.available-outside .rbc-event-content {
  text-align: center;
}

.rbc-btn-group {
  display: flex;
}

/* Reordering today-back-next buttons to back-today-next */
/* Today button */
.rbc-toolbar .rbc-btn-group button:nth-child(1) {
  order: 2;
  border-radius: 0;
  border-left: 0;
}

/* Back button */
.rbc-toolbar .rbc-btn-group button:nth-child(2) {
  order: 1;
  border-radius: 0;
}

.rbc-toolbar .rbc-btn-group button:nth-child(3) {
  order: 3;
  border-radius: 0;
}
